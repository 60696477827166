































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { vxm } from '@/store'
import ConfirmationInput from '@/components/ConfirmationInput.vue'
import AccountService from '@/services/economy/AccountService'
import VatCodeService from '@/services/economy/VatCodeService'
import VatCode from '@/models/economy/VatCode'
import Account from '@/models/economy/Account'
import VatCategoryService from '@/services/economy/VatCategoryService'

@Component({
  components: {
    ConfirmationInput,
  },
  directives: {},
})
export default class Edit extends Vue {
  private isSaving = false
  private allAccounts = []
  private vatCategories = []
  private data: VatCode = null
  private original: VatCode = null
  private rules = {}
  private error = ''

  public created(): void {
    this.data = new VatCode()
    this.original = new VatCode()
    this.rules = {
      name: [(v) => !!v || this.$t('c:validation:This field is required')],
      percent: [(v) => !isNaN(parseFloat(v)) || this.$t('c:validation:Must be a number')],
    }
    this.load()
  }

  private get id(): number {
    return this.$route.params.id ? parseInt(this.$route.params.id) : null
  }

  private get isNew() {
    return !!this.$route.meta.isNew
  }

  private load() {
    AccountService.loadAll()
      .then((accounts) => {
        this.allAccounts = accounts
        VatCategoryService.loadAll().then((vatCategories) => {
          this.vatCategories = vatCategories
          if (!this.isNew) {
            VatCodeService.loadOne(this.id)
              .then((vatCode) => {
                this.data = vatCode
                this.original = vatCode.clone()
              })
              .catch((err) => {
                vxm.alert.onAxiosError(err)
              })
          }
        })
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err)
      })
  }

  private get form() {
    return this.$refs.form as Vue & { validate: () => boolean }
  }

  private clickSave() {
    if (!this.form.validate()) {
      return
    }
    if (this.isNew) {
      VatCodeService.create(this.data)
        .then((_response) => {
          this.$router.back()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Could not save account')
        })
    } else {
      this.data.id = this.id
      VatCodeService.update(this.data)
        .then((_response) => {
          this.$router.back()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Could not save account')
        })
    }
  }

  private clickCancel() {
    this.$router.back()
  }

  private get vatAccounts(): Account[] {
    const result = []
    this.allAccounts.forEach((a) => {
      if (a.isTypeVat) {
        result.push(a)
      }
    })
    return result
  }
}
