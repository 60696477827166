import { axios } from '@/plugins/vueaxios'
import VatCategory from '@/models/economy/VatCategory'

export default class VatCategoryService {
  public static loadAll(): Promise<VatCategory[]> {
    return new Promise((resolve, reject) => {
      return axios
        .get('/v4/site/economy/vat-categories?perPage=500')
        .then((response) => {
          const result = []
          response.data.data.forEach((v) => {
            result.push(new VatCategory(v))
          })
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
